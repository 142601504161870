.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.project-body-background {
  width: 100%;
  min-height: 100vh;
  background-color: #282c34;
}
.project-body-display {
  background-color: #3d4452;
  width: 80%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  margin: 30px auto;
}

.project-body-display h1 {
  color:aliceblue;
  width: 100%;
  text-align: start;
}


.project-body-display-2 {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding-bottom: 50px;
}

.project-body-display-buttons {
  display:grid;
}

.download-button {
  display: inline-block;
  padding: 15px 60px;
  color: white;
  background-color: #3d4452;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  border: 2px solid #008BAE;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s;
  font-family: bold;
  font-size: 18px;
  max-height: 60px;
}

.open-channel-button {
  display: inline-block;
  padding: 15px 60px;
  color: white;
  background-color: #3d4452;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  border: 2px solid red;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s;
  font-family: bold;
  font-size: 18px;
  max-height: 60px;
}

.video-player {
  border-radius: 20px;
}

.implementation-parent {
  width: 100%;
  align-items: left;
  text-align: left;
  padding-bottom: 50px;
}

.implementation-parent h3 {
  color:aliceblue;
  margin-left: 10px;
}

.implementation-parent code {
  color:aliceblue;
  padding: 10px 20px;
  border: 2px solid gray;
  border-radius: 10px;   
}

.implementation-parent code:hover {
  border: 2px solid aqua;
}

.more-info-project {
  width: 100%;
  align-items: left;
  text-align: left;
  padding-bottom: 50px;
  color:aliceblue;
  font-size: 18px;
}


.project-body-display-images img {
  max-height: 200px;

}

.project-body-display-images {
  width: 50%;
}


.grade-stars-container {
  width: 100%;
  padding: 30px 10px;
}

.grade-stars-container img {
  width: 30px;
  padding: 5px;
}

.grade-stars-container h1 {
  color: aliceblue;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .project-body-display-2 {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .project-body-display-buttons {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  .video-player-container {
    width: 40%;
  }
  .video-player{
    border-radius: 30px;
  }
}




.published-project-more-info-container {
  color: aliceblue;
  padding: 10px 20px;
}

.published-project-more-info-container h2 {
  width: 100%;
  text-align: left;
}

.published-project-more-info-box {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.published-project-more-info-left, 
.published-project-more-info-right {
  width: 35%;
}

.published-project-more-info-left h4, 
.published-project-more-info-right h4 {
  padding-top: 10px;
  margin: 0px;
}

.projects-body-background {
  width: 100%;
  min-height: 100vh;
  background-color: #282c34;
}
.projects-body-display {
  background-color: #3d4452;
  width: 80%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  margin: 30px auto;
}

.projects-body-display h2 {
  color: aliceblue;
  font-family: bold;
}

.projects-body-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 50px;
}
.projects-body-container-not-full {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 50px;
  column-gap: 5%;
}


.projects-container-item {
  width: 29%;
  height: fit-content;
  cursor: pointer;
}


.projects-container-item img {
  width: 100%;
  border-radius: 10px;
}
.projects-container-item h3 {
  width: 100%;
  color: aliceblue;
}


@media screen and (max-width: 900px) {
  .projects-body-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .projects-container-item {
    width: 100%;
    margin-bottom: 40px;
  }
}
/*


*/

.published-projects-body-container-not-full {
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: 40px;
  padding-bottom: 50px;
  column-gap: 5%;
}

.published-projects-container-item {
  width: 29%;
  height: fit-content;
  cursor: pointer;
  align-items: center;
}
.published-projects-container-item img {
  width: 70%;
  border-radius: 10px;
}
.published-projects-container-item h3 {
  width: 100%;
  color: aliceblue;
}

@media screen and (max-width: 900px) {
  .projects-body-container-not-full {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .published-projects-container-item {
    width: 100%;
  }
}

.code-snippet {
  position: relative;
  width: 90%;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
}

#code-text {
  text-align: left;
  display: flex;
  justify-content: start; /* Align code content to the left */
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.footer {
  background-color: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer p {
  margin: 0;
}


.latest-projects-footer h2 {
  color: aliceblue;
}



.home-body-background {
  width: 100%;
  height: 100vh;
  background-color: #282c34;
}
.home-body-display {
  background-color: #3d4452;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

.home-body-display h1 {
  color: aliceblue;
}
.home-body-display h4 {
  color: aliceblue;
  margin-top: 40px;
}

.start-button {
  display: inline-block;
  padding: 15px 35px;
  background-color: #04b0b0;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s;
  font-family: bold;
  font-size: 18px;
  margin-bottom: 30px;
}

.start-button:hover {
  background-color: #036969;
}


@media screen and (max-width: 900px) {
  .home-body-display {
    width: 70%;
  }

  .latest-projects-footer {
    padding: 30px;
  }
}


.header {
  background-color: #333;
  padding: 15px 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo h1 {
  color: #fff;
  margin: 0;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
}

.nav-links li a:hover {
  color: #ffd700;
}




/*  NOT FOUND */

.not-found-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.not-found-title {
  font-size: 3rem;
  font-weight: 700;
  margin: 0.5rem 0;
}

.not-found-text {
  font-size: 1.25rem;
  color: #a5a5a5;
}

.not-found-container h2, 
.not-found-container p {
  background-color: #3d414f;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.not-found-container button {
  background-color: #00bfa6;
  border: none;
  color: white;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1.5rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.not-found-container button:hover {
  background-color: #008c7a;
  transform: translateY(-3px);
}

.not-found-container button:active {
  background-color: #006e5d;
  transform: translateY(0);
}
